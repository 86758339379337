import React, { useState } from 'react';
import { Box, Button, Image, Text } from 'grommet';
import LoadingModal from 'components/LoadingModal';
import { GenderType } from 'pages/App';

const BoxTemplate = ({ id, title, subtitle, imgUrl }: GenderType) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  if (id < 0) {
    return null;
  }

  return (
    <Box
      border="all"
      pad="medium"
      margin="medium"
      gap="small"
      round
    >
      <Image src={imgUrl} />

      <Text
        margin={{ top: "medium", horizontal: "small" }}
        size="xlarge"
        color="#ccd6f6"
        weight="bold"
      >
        {title}
      </Text>

      <Text
        margin={{ bottom: "small", horizontal: "small" }}
        size="medium"
        color="#8892b0"
      >
        {subtitle}
      </Text>

      <Button
        primary
        margin={{ horizontal: "small" }}
        size="large"
        label="선택하기"
        onClick={() => {
          setOpenModal(true);
          setIsCompleted(false);
        }}
      />

      <LoadingModal
        id={id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        isCompleted={isCompleted}
        setIsCompleted={setIsCompleted}
      />
    </Box>
  );
};

export default BoxTemplate;
