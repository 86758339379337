import React, { useState, useEffect, useRef } from 'react';
import { Grid, Text, Image, Grommet, grommet, Page, PageContent, Box } from 'grommet';
import { deepMerge } from 'grommet/utils';
import BoxTemplate from 'components/BoxTemplate';
import { motion } from "framer-motion";

import BoyGirl from "assets/boy_girl_dark.png";
import GirlBoy from "assets/girl_boy_dark.png";
import GirlGirl from "assets/girl_girl_dark.png";
import BoyBoy from "assets/boy_boy_dark.png";
import Image1 from "assets/img01.jpg";
import Image2 from "assets/img02.jpg";


const theme = deepMerge(grommet, {
  global: {
    font: {
      family: 'IBM Plex Sans KR',
    },
  }
});

export interface GenderType {
  id: number;
  title: string;
  subtitle: string;
  imgUrl: string;
};

const data: Array<GenderType> = [
  {
    id: 1,
    title: "다정 남자 & 다감 여자",
    subtitle: "오빠와 여동생, 균형있는 남매 쌍둥이",
    imgUrl: BoyGirl,
  },
  {
    id: 2,
    title: "다정 여자 & 다감 남자",
    subtitle: "누나와 남동생, 균형있는 남매 쌍둥이",
    imgUrl: GirlBoy,
  },
  {
    id: 3,
    title: "다정 여자 & 다감 여자",
    subtitle: "언니와 여동생, 귀여운 자매 쌍둥이",
    imgUrl: GirlGirl,
  },
  {
    id: 4,
    title: "다정 남자 & 다감 남자",
    subtitle: "형과 남동생, 씩씩한 형제 쌍둥이",
    imgUrl: BoyBoy,
  },
  {
    id: -1,
    title: "",
    subtitle: "",
    imgUrl: "",
  },
  {
    id: -1,
    title: "",
    subtitle: "",
    imgUrl: "",
  },
];

const Intro = () => {
  const introMargin = {
    top: "xlarge",
    bottom: "small",
    horizontal: "small"
  };

  const contentMargin = {
    top: "xsmall",
    horizontal: "small",
  };

  const descriptionMargin = {
    top: "medium",
    bottom: "xlarge",
    horizontal: "small"
  };

  const imageMargin = {
    top: "xsmall",
    bottom: "large",
    horizontal: "small",
  };

  const fadeInVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 100,
    delay: 0.2,
  }

  return (
    <motion.div initial="initial" animate="animate" variants={fadeInVariants} transition={spring}> 
      <Box pad="large" alignContent="center">
        <Text margin={introMargin} size="medium" color="#6FFFB0">안녕하세요, 요한 & 벼울입니다</Text>
        <Text margin={contentMargin} size="3xl" color="#8892b0" weight="bold">한별 부부에게</Text>
        <Text margin={contentMargin} size="2xl" color="#ccd6f6" weight="bold">쌍둥이가 생겼어요!</Text>
        <Text margin={descriptionMargin} size="medium" color="#8892b0">
          쌍둥이의 태명은 '다정 & 다감' 이랍니다.<br/>
          2023년 7월 중순, 다정다감은 빛을 봅니다.<br/>
          벼울이와 다정다감의 건강을 기도해주시고 축복해주시면 감사하겠습니다!
        </Text>
        <Image margin={imageMargin} src={Image1} />
        <Image margin={imageMargin} src={Image2} />
      </Box>
    </motion.div>
  );
};

const SelectHeader = () => {
  const introMargin = {
    top: "xlarge",
    bottom: "small",
    horizontal: "small"
  };

  const contentMargin = {
    top: "xsmall",
    horizontal: "small",
  };

  const descriptinMargin = {
    top: "medium",
    horizontal: "small"
  };

  return (
    <Box pad="large" alignContent="center">
      <Text margin={introMargin} size="large" color="#6FFFB0">다정다감 성별 예측</Text>
      <Text margin={contentMargin} size="2xl" color="#8892b0" weight="bold">다정다감의 성별을</Text>
      <Text margin={contentMargin} size="2xl" color="#ccd6f6" weight="bold">맞춰보세요!</Text>
      <Text margin={descriptinMargin} size="medium" color="#8892b0">
        다정은 첫째·선둥이, 다감은 둘째·후둥이입니다.<br/>
      </Text>
    </Box>
  );
};

const Card = (
  { id, title, subtitle, imgUrl }: GenderType) => {
  return (
    <BoxTemplate
      key={title}
      title={title}
      subtitle={subtitle}
      imgUrl={imgUrl}
      id={id}
    />
  );
};

const App = () => {
  const [visibleCards, setVisibleCards] = useState<GenderType[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter(
          (entry) => entry.isIntersecting
        );

        if (visibleEntries.length === 0) {
          return;
        }

        const lastIndex = visibleEntries[visibleEntries.length - 1].target.getAttribute(
          "data-index"
        );

        setVisibleCards(data.slice(0, parseInt(lastIndex ? lastIndex : "", 10) + 1));
      },
      {
        threshold: 0.5,
      }
    );

    const cards = container.querySelectorAll("[data-index]");
    cards.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  return (
    <Grommet theme={theme} full background="#0a192f">
      <Intro />
      <Page>
        <SelectHeader />
        <PageContent pad="large">
          <Grid columns="medium" gap="large" pad={{ bottom: "medium" }} ref={containerRef}>
            {
              data.map((item: GenderType, index: number) => {
                return (
                  <motion.div
                    key={index}
                    data-index={index}
                    style={{ flex: 1 }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={
                      visibleCards.includes(item)
                        ? { y: 0, opacity: 1 }
                        : undefined
                    }
                  >
                    <Card {...item} />
                  </motion.div>
                );
              })
            }
          </Grid>
        </PageContent>
      </Page>
    </Grommet>
  );
}

export default App;
