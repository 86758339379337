import React, { useState } from 'react';
import { Box, Button, Image, Layer, Text } from 'grommet';
import Countdown from "react-countdown";
import ClockLoader from "react-spinners/ClockLoader";
import Confetti from 'react-confetti';
import Result from "assets/result.jpg";

interface LoadingModalType {
  id: number;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isCompleted: boolean;
  setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

const data = [
  {
    id: 1,
    title: "맞았어요!",
    subtitle: "어떻게 알았죠?",
    description: "오빠와 여동생, 남매 쌍둥이래요! 행복해요 🥰",
  },
  {
    id: 2,
    title: "틀렸어요!",
    subtitle: "약간 비슷했지만..",
    description: "정답은 오빠와 여동생, 남매 쌍둥이 조합이랍니다!",
  },
  {
    id: 3,
    title: "틀렸어요!",
    subtitle: "자매 쌍둥이도 좋겠지만..",
    description: "정답은 오빠와 여동생, 남매 쌍둥이 조합이랍니다!",
  },
  {
    id: 4,
    title: "틀렸어요!",
    subtitle: "형제 쌍둥이도 좋겠지만..",
    description: "정답은 오빠와 여동생, 남매 쌍둥이 조합이랍니다!",
  },
];

const LoadingModal = ({ id, openModal, setOpenModal, isCompleted, setIsCompleted }: LoadingModalType) => {
  const [congrats, setCongrats] = useState<boolean>(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsCompleted(false);
  }

  if (openModal) {
    return (
      <Layer position="center" modal={true} margin="large" onClickOutside={() => handleCloseModal()} onEsc={() => handleCloseModal()}>
        <Box
          width="500px"
          align="center"
          justify="center"
          pad={{horizontal: "xlarge", bottom: "xlarge"}}
          background="black"
          margin={{top: "xlarge"}}
        >
          <ClockLoader
            color="#ccd6f6"
            loading={openModal}
          />
          <Countdown
            date={Date.now() + 6000}
            intervalDelay={0}
            precision={3}
            renderer={
              props => {
                if (props.completed) {
                  setOpenModal(false);
                  setIsCompleted(true);
                }
                if (props.seconds >= 1) {
                  return (
                    <Text color="#ccd6f6" size="6xl" weight="bold" margin="large">{props.seconds}</Text>
                  );
                } else {
                  return (
                    <Text color="#ccd6f6" size="6xl" weight="bold" margin="large">0.{props.milliseconds}</Text>
                  )
                }
              }
            }
          />
          <Button label='뒤로가기' primary={true} onClick={() => handleCloseModal()} />
        </Box>
      </Layer>
    )
  }

  if (isCompleted) {
     return (
      <Layer position="center" onClickOutside={() => setIsCompleted(false)} onEsc={() => setIsCompleted(false)}>
        <Box
          align="center"
          justify="center"
          direction="column"
          alignSelf="center"
          pad="large"
          alignContent="center"
        >
          <Text margin={{ top: "xlarge", bottom: "small", horizontal: "small" }} size="medium" color="#6FFFB0">다정다감의 성별을 공개합니다!</Text>
          <Text margin={{ top: "xsmall", horizontal: "small" }} size="3xl" color="#8892b0" weight="bold">{data[id-1].title}</Text>
          <Text margin={{ top: "xsmall", horizontal: "small" }} size="2xl" color="#ccd6f6" weight="bold">{data[id-1].subtitle}</Text>
          <Text margin={{ top: "medium", bottom: "medium", horizontal: "small" }} size="medium" color="#8892b0">{data[id-1].description}</Text>

          <Button
            label='축하하기'
            primary={true}
            margin={{ bottom: "large" }}
            onClick={
              () => {
                setIsCompleted(false);
                setCongrats(true);
              }
            }
          />

          <Image margin={{ top: "xsmall", bottom: "large", horizontal: "small" }} fill="horizontal" src={Result} />
        </Box>
      </Layer>
    );
  }

  if (congrats) {
    return <Confetti/>;
  }

  return null;
};

export default LoadingModal;